<style>
.btn__next,
.btn__prev {
    display: none;
}
.vel-img-title {
    font-size: 100px;
}
.wide-popover {
    min-width: 200px;
    max-width: 600px;
}
#inputFigure .invalid-feedback {
    margin-top: 10px;
}
#inputFigure legend {
    margin-top: 0px;
}
</style>
<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button @click="showModalVariety('')" variant="primary" v-if="hasPermission">Add variety</b-button>
                <b-modal id="varieties-modal" ref="modal" :title="varietiesEntity.id > 0 ? 'Edit variety' :'Add variety'" size="lg" centered no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver v-slot="{ passes }">
                        <b-form @submit.prevent="passes(saveVarieties)" id="varieties-form">
                            <b-row>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Name" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="varietiesEntity.name" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Crop family" :invalid-feedback="errors[0]" :clearable="false" :state="errors[0] ? false : null">
                                            <v-select label="text" :options="parentalFamily" @input="unselectedFamily" @search="getCropFamily" @search:focus="getCropFamily"
                                                      :reduce="cropFamilyId => cropFamilyId.value" v-model="varietiesEntity.cropFamilyId" placeholder="Select crop family">
                                                <template #no-options>No results</template>
                                            </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Crop" :invalid-feedback="errors[0]" :clearable="false" :state="errors[0] ? false : null">
                                            <v-select label="text" :options="cropEntity" :disabled="isDisabled" @search="getCrop" @search:focus="getCrop"
                                                      :reduce="cropId => cropId.value" v-model="varietiesEntity.cropId" placeholder="Select crop">
                                                <template #no-options>No results</template>
                                            </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Female parent" :invalid-feedback="errors[0]" :clearable="false" :state="errors[0] ? false : null">
                                            <v-select label="text" :options="parentFemale" :disabled="isDisabled" :filterable="false" @search="getParentFemale" @search:focus="getParentFemale"
                                                      v-model="varietiesEntity.parentalFemale" placeholder="Select female parent">
                                                <template #no-options>No results</template>
                                            </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Male parent" :invalid-feedback="errors[0]" :clearable="false" :state="errors[0] ? false : null">
                                            <v-select label="text" :options="parentMale" :disabled="isDisabled" @search="getParentMale" @search:focus="getParentMale"
                                                      v-model="varietiesEntity.parentalMale" placeholder="Select male parent">
                                                <template #no-options>No results</template>
                                            </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required|integer|min_value:0|max_value:10" v-slot="{ errors }">
                                        <b-form-group label="Current phase" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="varietiesEntity.phaseCurrent" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                        <b-form-group label="TSW" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="varietiesEntity.tws" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="MS" :invalid-feedback="errors[0]" :clearable="false" :state="errors[0] ? false : null">
                                            <v-select label="text" v-model="varietiesEntity.ms" :options="['1 Non sterile', '2 Sterile']" placeholder="Select MS"
                                                      :class="{'is-invalid': errors.length > 0}">
                                            </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Pollination type" :invalid-feedback="errors[0]" :clearable="false" :state="errors[0] ? false : null">
                                            <v-select label="text" :options="pollinationType" @search:focus="getPollinationType" @search="getPollinationType"
                                                      v-model="varietiesEntity.pollinationTypeId" :reduce="pollinationTypeId => pollinationTypeId.value"
                                                      placeholder="Select pollination">
                                                <template #no-options>No results</template>
                                            </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="6" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Harvest method" :invalid-feedback="errors[0]" :clearable="false" :state="errors[0] ? false : null">
                                            <v-select :options="harvestMethod" :disabled="isDisabled" @search:focus="getHarvestMethod" @search="getHarvestMethod"
                                                      v-model="varietiesEntity.harvestMethod"  label="text" :reduce="harvestMethod => harvestMethod.value"
                                                      placeholder="Select harvest method">
                                                <template #no-options>No results</template>
                                            </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" sm="12">
                                    <ValidationProvider rules="required|integer|min_value:0" v-slot="{ errors }">
                                        <b-form-group label="Females per male plant" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="varietiesEntity.femalePerPlants" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="2" sm="12">
                                    <b-form-group label="Inbred risk">
                                        <b-button size="sm" variant="outline-primary" @click="varietiesEntity.inbredRisk = !varietiesEntity.inbredRisk">
                                            <font-awesome-icon v-if="!varietiesEntity.inbredRisk" icon="toggle-off"></font-awesome-icon>
                                            <font-awesome-icon v-else icon="toggle-on"></font-awesome-icon>
                                        </b-button>
<!--                                        <b-form-input v-model="varietiesEntity.inbredRisk" style="display: none"></b-form-input>-->
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group label="Remarks">
                                        <b-form-textarea v-model="varietiesEntity.remark" rows="3"></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-form>
                </ValidationObserver>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button @click="$bvModal.hide('varieties-modal')" class="mr-2" variant="outline-secondary"> Cancel</b-button>
                            <b-button type="submit" form="varieties-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
                        </div>
                    </template>
                </b-modal>
            </b-col>
        </b-row>

        <b-modal id="varieties-files-modal" ref="modal" title="Figure graphic" size="lg" centered no-close-on-backdrop no-close-on-esc>
            <ValidationObserver v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveFile)" id="files-form">
                    <b-row>
                    <b-col md="6" sm="12">
                        <ValidationProvider rules="required|image" v-slot="{ errors }">
                            <b-form-group label="Figure graphic" id="inputFigure" style="margin-top:0px;" :invalid-feedback="errors[0]">
                                <b-form-file v-model="varietiesEntity.figureGraphic" type="image" accept="image/*" :state="errors[0] ? false : null">
                                </b-form-file>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col md="6" sm="12">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <b-form-group label="Figure title" invalid-feedback="File is required">
                                <b-form-input v-model="varietiesEntity.figureName" autocomplete="off" :state="errors[0] ? false : null"></b-form-input>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </b-form>
            </ValidationObserver>
            <template #modal-footer>
                <div class="text-right">
                    <b-button @click="$bvModal.hide('varieties-files-modal')" class="mr-2" variant="outline-secondary"> Cancel</b-button>
                    <b-button type="submit" form="files-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
                </div>
            </template>
        </b-modal>

        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Show" append="records">
                    <b-form-select v-model="varieties.itemsPerPage" id="perPageSelect" size="sm" :options="varieties.pageOptions" style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
<!--            <b-col>-->
<!--                <div class="mt-3 d-flex justify-content-end">-->
<!--                    <b-button-group>-->
<!--                        <b-button :variant="colorNoInbredRisk" @click="changeVarietyInbredRisk(0)">No inbred risk</b-button>-->
<!--                        <b-button :variant="colorInbredRisk" @click="changeVarietyInbredRisk(1)">Inbred risk</b-button>-->
<!--                    </b-button-group>-->
<!--                </div>-->
<!--            </b-col>-->
        </b-row>

        <vue-easy-lightbox escDisabled moveDisabled :visible="visible" :imgs="imgs" :index="index" @hide="handleHide"></vue-easy-lightbox>

        <b-table :items="loadData" :fields="filteredFields" :filter="varieties.filter" :current-page="varieties.currentPage"
                 :per-page="varieties.itemsPerPage" empty-text="No records" striped bordered hover small show-empty :responsive="true"
                 :sort-by="varieties.sortBy" :sort-desc="varieties.sortDesc" ref="varietyTable" class="text-left mb-0" @context-changed="tableContextChanged">

            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in filteredFields" :key="field.key" :class="varieties.filters[index] && varieties.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="varieties.filters[index]">
                            <b-col v-if="varieties.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="varieties.filters[index].value" debounce="300"></b-input>
                            </b-col>
                            <b-col v-if="varieties.filters[index].inputType === 'Select'">
                                <b-select v-model="varieties.filters[index].value">
                                    <b-select-option value="">All</b-select-option>
                                    <b-select-option value="1">Inbred risk</b-select-option>
                                    <b-select-option value="0">No inbred risk</b-select-option>
                                </b-select>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(name)="row">
              <div class="text-center">
                  <b-img class="img-fluid" style="width: 31px;" v-if="row.item.figureGraphic" :src="urlImg + row.item.fileId"
                         @click="showImg(index, row.item)" :alt="row.item.name"></b-img>
                  <p class="font-bold no-margins">
                      {{ row.item.name }}
                      <span class="label" style="width: 1px; color:#fe9d11;" v-b-tooltip.hover :title="row.item.inbredRisk == true ? 'Inbred risk' : ''">
                        <font-awesome-icon v-if="row.item.inbredRisk == true" icon="exclamation-triangle"></font-awesome-icon>
                      </span>
                  </p>
                  <p class="no-margins" v-if="row.item.figureGraphic" >{{ row.item.figureName }}</p>
              </div>
            </template>

            <template #cell(ms)="row">
                <p>{{ row.item.ms }}</p>
            </template>

            <template #cell(remark)="row">
                <div style="width: 190px !important;">
                    <template v-if="row.item.remark !== ''">
                        <b-popover :target="'variety-remark-' + row.item.id" triggers="click blur" custom-class="wide-popover" placement="bottom">
                            <template #title>Remarks</template>
                            {{ row.item.remark}}
                        </b-popover>
                        <p :id="'variety-remark-' + row.item.id" class="variety-remark"
                            style="white-space: nowrap; width: 190px; overflow: hidden; text-overflow: ellipsis;">
                            {{ row.item.remark}}
                        </p>
                    </template>
                </div>
            </template>

            <template #cell(inbredRisk)="row">
                <div class=" d-flex justify-content-center">
                    <font-awesome-icon icon="check" v-if="row.item.inbredRisk" style="color: var(--color-primary)"></font-awesome-icon>
                    <font-awesome-icon icon="times" v-else style="color: #bf0d0d"></font-awesome-icon>
                </div>
            </template>

            <template #cell(actions)="row" v-if="hasPermission">
                <b-button-group size="sm">
                    <b-button size="sm" @click="showModalFile(row.item)" v-b-tooltip.hover title="Load figure graphic" variant="outline-primary">
                        <font-awesome-icon icon="image"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" @click="showModalVariety(row.item)" v-b-tooltip.hover title="Edit" variant="outline-primary">
                        <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" @click="deleteVariety(row.item.id)" v-b-tooltip.hover title="Delete" variant="outline-primary">
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Show {{ start }} to {{ end }} of {{ varieties.totalRows }} record{{varieties.totalRows ? 's' : ''}}
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="varieties.currentPage" :total-rows="varieties.totalRows" :per-page="varieties.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>
<script>
import { extend } from 'vee-validate';
import { VarietiesEntity } from '../../models/varieties';
import { varietySaveUrl, varietyListUrl, varietyDeleteUrl, varietyCropFamilySelectUrl,
    varietyCropSelectUrl, varietyFemaleSelectUrl, varietyMaleSelectUrl, varietyPollinationTypeSelectUrl,
    varietyChangeStatus, varietyAddImage, varietyHarvestMethodUrl, getImgUrl, showOriginalImgUrl } from '@routes';
import tableStateMixin from '../../mixins/tableState';
import { required, integer, min_value, max_value, image } from 'vee-validate/dist/rules';
import Utilities from '../../js/utilities';
import VueEasyLightbox from 'vue-easy-lightbox';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
import $permission from '@/js/permission';

extend('required', {
    ...required,
    message: 'Field required'
});

extend('integer', {
    ...integer,
    message: 'The value must be an integer'
});

extend('min_value', {
    ...min_value,
    message: 'Must be greater than 0'
});

extend('image', {
    ...image,
    message: 'Required an image file'
});

extend('max_value', {
    ...max_value,
    message: 'The value must be between 1 to 10'
});

extend('number', value => {
    if (Utilities.isNumeric(value)) {
        return true;
    }
    return 'The value must be a number';
});
export default {
    components: {
        VueEasyLightbox
    },
    mixins: [tableStateMixin],
    data() {
        return {
            varietiesEntity: VarietiesEntity.create(),
            toastTitle: 'Varieties',
            varieties: {
                fields: [
                    { key: 'name', label: 'Variety', sortable: true },
                    { key: 'cropFamily', label: 'Crop family', sortable: true },
                    { key: 'crop', label: 'Crop', sortable: true },
                    { key: 'parentalFemale', label: 'Female parent', sortable: true },
                    { key: 'parentalMale', label: 'Male parent', sortable: true },
                    { key: 'phaseCurrent', label: 'Current phase', sortable: true },
                    { key: 'harvestMethodName', label: 'Harvest method', sortable: true },
                    { key: 'femalePerPlant', label: 'Females per male plant', sortable: true },
                    { key: 'tws', label: 'TWS', sortable: true, class: 'text-center', thStyle: 'width: 30px' },
                    { key: 'ms', label: 'MS', sortable: true, class: 'text-center', thStyle: 'width: 72px' },
                    { key: 'remark', label: 'Remarks', sortable: true },
                    { key: 'inbredRisk', label: 'Inbred risk', sortable: true },
                    // { key: 'createdBy', label: 'Created by', sortable: true, class: 'text-center' },
                    // { key: 'updatedBy', label: 'Updated by', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                fieldsList: [
                    { key: 'name', label: 'Variety', sortable: true },
                    { key: 'cropFamily', label: 'Crop family', sortable: true },
                    { key: 'crop', label: 'Crop', sortable: true },
                    { key: 'parentalFemale', label: 'Female parent', sortable: true },
                    { key: 'parentalMale', label: 'Male parent', sortable: true },
                    { key: 'phaseCurrent', label: 'Current phase', sortable: true },
                    { key: 'harvestMethodName', label: 'Harvest method', sortable: true },
                    { key: 'femalePerPlant', label: 'Females per male plant', sortable: true },
                    { key: 'tws', label: 'TWS', sortable: true, class: 'text-center', thStyle: 'width: 30px' },
                    { key: 'ms', label: 'MS', sortable: true, class: 'text-center', thStyle: 'width: 72px' },
                    { key: 'remark', label: 'Remarks', sortable: true },
                    { key: 'inbredRisk', label: 'Inbred risk', sortable: true }
                    // { key: 'createdBy', label: 'Created by', sortable: true, class: 'text-center' },
                    // { key: 'updatedBy', label: 'Updated by', sortable: true, class: 'text-center' }
                ],
                filters: [
                    { column: 'name', type: 'STRING', value: '', format: '' },
                    { column: 'cropFamily', type: 'STRING', value: '', format: '' },
                    { column: 'crop', type: 'STRING', value: '', format: '' },
                    { column: 'parentalFemale', type: 'STRING', value: '', format: '' },
                    { column: 'parentalMale', type: 'STRING', value: '', format: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { column: 'inbredRisk', type: 'BOOLEAN', value: '', format: '', inputType: 'Select' }
                ],
                filter: '',
                pageOptions: [10, 25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'name',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Varieties',
                path: []
            },
            parentalFamily: [],
            cropEntity: [],
            parentFemale: [],
            parentMale: [],
            harvestMethod: [],
            pollinationType: [],
            fileModal: null,
            visible: false,
            imgs: '',
            index: 0,
            urlImg: getImgUrl(),
            urlShowImg: showOriginalImgUrl(),
            isDisabled: true,
            colorInbredRisk: 'outline-primary',
            colorNoInbredRisk: 'outline-primary',
            allColor: 'outline-primary',
            varietyInbredRisk: true,
            varietyNoInbredRisk:false,
            inbredRiskData: [],
            isInbredRisk: false,
            hasPermission: $permission.check('ROLE_VARI_C')
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.varieties, this.tableState);
        // this.changeVarietyInbredRisk(0);
    },
    methods: {
        loadData(varieties) {
            if (varieties.sortBy !== '') {
                let data = {
                    filters: this.varieties.filters.filter(filter => filter.value.trim() !== ''),
                    ...varieties
                };
                data.currentPage = (data.currentPage - 1) * data.perPage;
                data.inbredRisk = this.varieties.inbredRisk;
                return this.axios.post(varietyListUrl(), data).then(response => {
                    if (response.data.code === 'OK') {
                        this.varieties.totalRows = response.data.totalRows;
                        return response.data.varieties;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else if (error.request) {
                        this.$bvToast.toast('An error occurred while executing the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else {
                        this.$bvToast.toast('An error occurred while configuring the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                });
            }
        },
        showModalVariety(row) {
            if (row.id > 0) {
                this.varietiesEntity = JSON.parse(JSON.stringify(row));
                this.getHarvestMethod();
                this.getCropFamily();
                this.getCrop();
                this.getParentFemale();
                this.getParentMale();
                this.getPollinationType();
            } else {
                this.varietiesEntity = VarietiesEntity.create();
            }
            this.$nextTick(() => {
                this.$bvModal.show('varieties-modal');
            });
        },
        showModalFile(variety) {
            this.fileModal = variety;
            this.varietiesEntity.figureName = variety.figureName;
            this.$bvModal.show('varieties-files-modal');
        },
        saveVarieties() {
            this.axios.post(varietySaveUrl(this.varietiesEntity.id), this.varietiesEntity).then(response => {
                if (response.data.code === 'OK') {
                    this.$bvModal.hide('varieties-modal');
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });
                    this.$refs.varietyTable.refresh();

                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        saveFile() {
            let formData = new FormData();
            formData.append('id', this.fileModal.id);
            formData.append('file', this.varietiesEntity.figureGraphic);
            formData.append('name', this.varietiesEntity.figureName);

            this.axios.post(varietyAddImage(), formData).then(response => {
                if (response.status === 200) {
                    this.$bvModal.hide('varieties-files-modal');
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });
                    this.$refs.varietyTable.refresh();
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        deleteVariety(varietyId) {
            this.$swal({
                title: 'Are you sure you want to delete the variety?',
                icon: 'warning',
                iconColor: '#bf0d0d',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(varietyDeleteUrl(), {
                        id: varietyId
                    }).then(response => {
                        if (response.data.code === 'OK') {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$refs.varietyTable.refresh();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    });
                }
            });
        },
        changeStatus(variety) {
            this.$swal({
                title: 'Are you sure to change inbred risk status?',
                html: `The inbred risk status will change to <strong>${!variety.inbredRisk ? 'activate' : 'inactivate'} <strong>`,
                icon: 'warning',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#97bf0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(varietyChangeStatus(), {
                        id: variety.id,
                        inbredRisk: !variety.inbredRisk
                    }).then(response => {
                        if (response.data.status === 200) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$refs.varietyTable.refresh();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    });
                }
            });
        },
        getCropFamily(loading) {
            if (loading) {
                loading(true);
            }
            this.axios.get(varietyCropFamilySelectUrl()).then(response => {
                this.parentalFamily = response.data.families;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() =>{
                if (loading) {
                    loading(false);
                }
            });
        },
        getCrop(search, loading) {
            if (loading) {
                loading(true);
            }
            this.axios.post(varietyCropSelectUrl(), {
                familyId: this.varietiesEntity.cropFamilyId,
                search: search
            }).then(response => {
                this.cropEntity = response.data.cropsByFamily;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() =>{
                if (loading) {
                    loading(false);
                }
            });
        },
        getParentFemale(search, loading) {
            if (loading) {
                loading(true);
            }
            this.axios.post(varietyFemaleSelectUrl(), {
                gender: 'female',
                familyId: this.varietiesEntity.cropFamilyId,
                search: search
            }).then(response => {
                this.parentFemale = response.data.parents;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() =>{
                if (loading) {
                    loading(false);
                }
            });
        },
        getParentMale(search, loading) {
            if (loading) {
                loading(true);
            }
            this.axios.post(varietyMaleSelectUrl(), {
                gender: 'male',
                familyId: this.varietiesEntity.cropFamilyId,
                search: search
            }).then(response => {
                this.parentMale = response.data.parents;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() =>{
                if (loading) {
                    loading(false);
                }
            });
        },
        getPollinationType(search, loading) {
            if (loading) {
                loading(true);
            }
            this.axios.get(varietyPollinationTypeSelectUrl()).then(response => {
                this.pollinationType = response.data.types;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() =>{
                if (loading) {
                    loading(false);
                }
            });
        },
        getHarvestMethod(search, loading) {
            if (loading) {
                loading(true);
            }
            this.axios.post(varietyHarvestMethodUrl(), {
                familyId: this.varietiesEntity.cropFamilyId,
                search: search
            }).then(response => {
                this.harvestMethod = response.data.harvestMethod;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() =>{
                if (loading) {
                    loading(false);
                }
            });
        },
        unselectedFamily(value) {
            if (value == null || value) {
                this.cropEntity = [];
                this.parentFemale = [];
                this.parentMale = [];
                this.harvestMethod = [];

                this.varietiesEntity.cropId = '';
                this.varietiesEntity.parentalFemale = '';
                this.varietiesEntity.parentalMale = '';
                this.varietiesEntity.harvestMethod = '';

            }
        },
        showImg(index, variety) {
            this.imgs = [
                {
                    src: this.urlImg + variety.fileId,
                    title: variety.name + ' - ' + variety.figureName,
                    alt: variety.name + ' - ' + variety.figureName
                }
            ];
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        // changeVarietyInbredRisk(status) {
        //     if (status === 1) {
        //         this.varietyInbredRisk = !this.varietyInbredRisk;
        //         if (!this.varietyInbredRisk) {
        //             this.colorInbredRisk = 'primary';
        //         } else {
        //             this.colorInbredRisk = 'outline-primary';
        //         }
        //     } else if (status === 0) {
        //         this.varietyNoInbredRisk = !this.varietyNoInbredRisk;
        //         if (this.varietyNoInbredRisk) {
        //             this.colorNoInbredRisk = 'primary';
        //         } else {
        //             this.colorNoInbredRisk = 'outline-primary';
        //         }
        //     }  else if (status === null) {
        //         this.colorAll = 'primary';
        //         this.colorNoInbredRisk = 'outline-primary';
        //         this.colorInbredRisk = 'outline-primary';
        //     }
        //
        //     let index = this.inbredRiskData.indexOf(status);
        //     if (index >= 0) {
        //         this.inbredRiskData.splice(index, 1);
        //     } else {
        //         this.inbredRiskData.push(status);
        //     }
        //
        //     this.varieties.inbredRisk = this.inbredRiskData.toString();
        //     this.$nextTick(() => {
        //         this.$refs.varietyTable.refresh();
        //     });
        //     this.saveTableState(this.$route.name, this.varieties);
        // },
        tableContextChanged(context) {
            this.varieties.sortBy = context.sortBy;
            this.varieties.sortDesc = context.sortDesc;
            this.saveTableState(this.$route.name, this.varieties);
        }
    },
    watch: {
        'varieties.filters': {
            handler() {
                this.$refs.varietyTable.refresh();
                this.saveTableState(this.$route.name, this.varieties);
            },
            deep: true
        },
        'varietiesEntity.cropFamilyId'() {
            this.isDisabled = !(this.varietiesEntity.cropFamilyId != null);
        }
    },
    computed: {
        start() {
            return this.varieties.totalRows === 0 ? 0 : (this.varieties.currentPage - 1) * this.varieties.itemsPerPage + 1;
        },
        end() {
            let offset = (this.varieties.currentPage - 1) * this.varieties.itemsPerPage;

            return (this.varieties.totalRows - offset) < this.varieties.itemsPerPage ? this.varieties.totalRows : offset + this.varieties.itemsPerPage;
        },
        filteredFields() {
            if (this.hasPermission) {
                return this.varieties.fields;
            } else {
                return this.varieties.fieldsList;
            }
        }
    }
};
</script>