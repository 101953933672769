import { v4 as uuidv4 } from 'uuid';

export class VarietiesEntity {
    constructor(id, name, cropId, figureName, figureGraphic, ms, inbredRisk, parentalMale, parentalFemale, remark,
        phaseCurrent, tws, pollinationTypeId, harvestMethod, femalePerPlants, fileName) {

        this.id = id;
        this.name = name;
        this.cropId = cropId;
        this.figureName = figureName;
        this.figureGraphic = figureGraphic;
        this.ms = ms;
        this.inbredRisk = inbredRisk;
        this.parentalMale = parentalMale;
        this.parentalFemale = parentalFemale;
        this.remark = remark;
        this.phaseCurrent = phaseCurrent;
        this.tws = tws;
        this.pollinationTypeId = pollinationTypeId;
        this.harvestMethod = harvestMethod;
        this.femalePerPlants = femalePerPlants;
        this.fileName = fileName;

        this.guid = uuidv4();
    }
    static create() {
        return new VarietiesEntity(0, '', '', '', [], '', 0, '', '',
            '', '', '', '', '', '', []);
    }

    static createFromObject(variety) {
        return new VarietiesEntity(variety.id, variety.name, variety.cropId, variety.figureName, variety.figureGraphic,
            variety.ms, variety.inbredRisk, variety.parentalMale, variety.parentalFemale, variety.remark,
            variety.phaseCurrent, variety.tws, variety.pollinationTypeId, variety.density, variety.harvestMethod,
            variety.femalePerPlants, variety.fileName);
    }
}
